import { waitAuth } from "~/utils/middleware";

import { PBEnums } from "~/types/pb/enums";

import { log } from "~/composables/log";

import Abonnements = PBEnums.Abonnements;

export default defineNuxtRouteMiddleware(async () => {
    await waitAuth(Abonnements.Partner);

    if (!__subscription().hasPartner) {
        log("❌ Has no Partner");
        return navigateTo("/");
    }

    log("✅ Has Partner");
});
